import Vue from 'vue' 

import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'firms.vue',
    component: () => import(/* webpackChunkName: "about" */ '../views/firms.vue')
  },
  {
    path: '/candidates',
    name: 'Candidates',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },

  {
    path: "/login",
    name: "login",
    component: () => import('../views/login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
