<template>
  <header class="ac-header">
    <div class="header-container">
      <a href="/">
        <img src="../assets/ttf-logo-white.png" alt="" class="logo">
      </a>
      <nav class="ac-header__navigation">
        <a class="ac-header__navigation-link" href="#advantages">{{$t('menuTitle1')}}</a>
        <a class="ac-header__navigation-link" href="#what-we-do">{{$t('menuTitle2')}}</a>
        <a class="ac-header__navigation-link" href="#opportunities">{{$t('menuTitle3')}}</a>
        <a class="ac-header__navigation-link" href="#contact">{{$t('menuTitle4')}}</a>
        <router-link v-if="currentPage === '/'" class="ac-header__navigation-link link-special-emphasis" to="/candidates">{{$t('menuTitleSpecialAlt')}}</router-link>
        <router-link v-else class="ac-header__navigation-link link-special-emphasis" to="/">{{$t('menuTitleSpecial')}}</router-link>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'acHeader',
  props: ["currentPage"]
}
</script>

<style scoped>


  header.ac-header{
    height: var(--header-height);
    display: flex;
    align-items: center;
    width: 100vw;
    background: linear-gradient(180deg, #323F4B 0%, #1F2933 100%);
    position: absolute;
    left: 0;
    z-index: 10;
  }

  .header-container{
    width: var(--site-width);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
  }

  img.logo{
    max-height: var(--header-height);
  }

  .ac-header__navigation{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 41.66777%;
  }

  a.ac-header__navigation-link{
    font-weight: 500;
    font-size: 1.4rem;
    color: #FFFFFF;
  }

  .link-special-emphasis{
    background-color: #F5BD4D;
    padding: 10px 10px;
    border-radius: 2px;
    color: #29323C !important;
    font-weight: 600 !important;
  }

  .link-special-emphasis:hover{
      background-color: #ffb726;
  }

      @media screen and (max-width: 1000px){
        .ac-header__navigation-link:not(:last-child){
          display: none;
        }

        .ac-header__navigation{
          justify-content: flex-end;
        }
    }

</style>
