<template>
  <footer class="">
    <div class="footer-container">
      <div class="footer-address-container text-white">
        <div class="flex footer-address-container__header items-center">
          <img class="footer-map-icon" width="24px" src="../assets/maps-and-flags.png" alt="Map Icon">
          <h3 class="text-2xl">Head Offices</h3>
        </div>
        <div class="footer-address text-xl">
          Kanyon AVM D Blok K3 D5 Levent Istanbul
        </div>

  <div class="footer-social-container">
          <a href="https://www.instagram.com/thetalentsfactory/">
            <img src="../assets/instagram.png" class="social-logo" width="32" alt="instagram logo">
          </a>
          <a href="https://twitter.com/talents_factory/">
            <img src="../assets/twitter.png" class="social-logo" width="32" alt="Twitter logo">
          </a>
          <a href="https://www.facebook.com/The-Talents-Factory-105658728748022">
            <img src="../assets/facebook.png" class="social-logo" width="32" alt="Facebook logo">
          </a>
          <a href="https://www.linkedin.com/company/thetalentsfactory-com">
            <img src="../assets/linkedin.png" class="social-logo" width="32" alt="Linkedin logo">
          </a>
        </div>
        </div>
     
      <img @click="scrollToTop" class="scroll-top" width="32px" height="32px" src="../assets/go-up.png" alt="Scroll to top icon">
    </div>
  </footer>
</template>

<script>
export default {
  name: 'acFooter',
  methods: {
      scrollToTop(){
          window.scrollTo({ top: 0, behavior: 'smooth' });
      }
  }
}
</script>

<style scoped>
    footer{
        position: absolute;
        left: 0;
        height: 14.1rem;
        background: linear-gradient(180deg, #323F4B 0%, #1F2933 100%);
        width: 100vw;
        display: flex;
        align-items: center;
      }

    .footer-container{
      width: var(--site-width);
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .scroll-top{
      filter: invert() brightness(100%);
      cursor: pointer;
    
    }

    img.footer-map-icon{
      margin-right: .5rem;
    }

    .footer-address {
      padding-left: 2.9rem;
    }

    .footer-social-container{
      display: flex;
      padding-left: 2.9rem;
      margin-top: 2rem;
      justify-content: space-between;
    }

    .social-logo{

    }

    

    @media screen and (max-width: 840px){
      

      img.footer-map-icon{
        margin-right: 0;
      }

      footer{
        height: auto;
      }

      .scroll-top{
        width: 40px;
        height: 40px;
        margin-bottom: 1rem
      }
      
      .footer-map-icon{
        width: 40px;
        height: 40px;
        margin-bottom: .2rem;
      }


      

      .footer-address-container{
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        align-content: center;
        justify-content: center;
        padding: 2rem 0;
      }

      .footer-address-container__header{
        flex-direction: column;
        align-content: center;
        justify-content: center;
        margin-bottom: 1rem;
      }
      

        .footer-container{
            flex-direction: column;
            justify-content: center;
        }
    }

</style>
