<template>
  <main class="app-container">
    <ac-header :currentPage="currentPage"></ac-header>
      <router-view></router-view>
    <ac-footer></ac-footer>
  </main>
</template>

<script>

import acFooter from "./components/acFooter.vue";
import acHeader from "./components/acHeader.vue";

export default {
  name: "App",
  data(){
    return{
      currentPage: ""
    }
  },
  components: {
    acFooter,
    acHeader,
  },

  methods: {
    getCurrentPage() {
      this.currentPage = window.location.pathname
    }
  },

created(){
  this.getCurrentPage()
},

updated(){
  this.getCurrentPage()
}
};
</script>

<style>
@import "./styles/resets.css";
@import "./styles/globals.css";



</style>